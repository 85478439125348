<script>
export default {
  name: "swithLanguage",
  data() {
    return {
      options: [
        {
          value: 'zh-cn',
          label: '中文',
        },
        {
          value: 'zh-tw',
          label: '繁体中文',
        },
        {
          value: 'en',
          label: 'English',
        },
        {
          value: 'ko',
          label: '한국어',
        }
      ],
      value: "zh-cn"
    }
  },
  methods: {
    changeLanguage(e) {
      this.$i18n.locale = e;
      window.localStorage.setItem("language", e)
    }
  },
  created() {
    let language = window.localStorage.getItem("language");
    if (language) {
      this.value = language
      this.$i18n.locale = language;
    }
  }
}
</script>

<template>
  <div>
    <el-select v-model="value" placeholder="Select" style="width: 100px" @change="changeLanguage">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
      />
    </el-select>
  </div>
</template>

<style scoped>


</style>
