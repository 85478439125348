<template>
  <img src="../assets/logo.png" class="logo" alt="" @click="gourl">
  <div class="language">
    <swithLanguage></swithLanguage>
  </div>
  <div class="hello">
    <!--    <div class="slgon">《{{ $t("pay.gameName") }}》<br/>{{ $t("pay.payText") }}</div>-->
    <div class="slgon">《{{ kami.data.game_title }}》<br/>{{ $t("pay.payText") }}</div>

    <div class="text">{{ $t("pay.payTotalTxt") }}</div>
    <div class="input_div">
      <div class="input_list">
        <el-input type="text" v-model="TikTokID" class="input" :placeholder="TikTokID">
          <template #prepend>TikTokID</template>
        </el-input>
        <el-input type="number" v-model="money" class="input" :placeholder="$t('pay.CNYText')" @input="num">
          <template #prepend>{{ $t("pay.CNY") }}</template>
        </el-input>
        <el-input type="text" v-model="surplus" class="input" disabled>
          <template #prepend>{{ $t("pay.gameBalance") }}</template>
        </el-input>
        <el-input type="number" v-model="number" class="input" disabled placeholder="请输入充值金额(人民币)">
          <template #prepend>{{ $t("pay.gameText") }}</template>
        </el-input>
        <el-button @click="go" class="button" type="primary" size="large" color="#636ff1">
          {{ $t("pay.wechatPay") }}
        </el-button>
        <el-button @click="paypal" style="margin-left: 0" class="button" type="primary" size="large" color="#636ff1">
          {{ $t("pay.paypalPay") }}
        </el-button>
        <el-button @click="logout" style="margin-left: 0" class="button" type="danger" size="large">
          {{ $t("pay.switchCard") }}
        </el-button>
      </div>
    </div>
    <el-drawer
        v-model="drawer"
        :title="$t('pay.wechatPayText')"
        direction="rtl"
        @close="closeDrawer"
    >
      <vue-qrcode :scale="options.scale" :value="pay_image" :widh="options.width" type="image/jpeg"
                  :color="options.color"/>
    </el-drawer>
    <div class="bottom">
      星遇互游 © 2024 All Rights by 湖北千纳文化发展有限公司.<br>
      鄂ICP备2021020027号-1
    </div>
  </div>
</template>

<script>


import {post, get} from "@/api";
import {ElLoading, ElMessage} from "element-plus";
import VueQrcode from 'vue-qrcode';
import swithLanguage from "@/components/swithLanguage.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Pay',
  components: {
    swithLanguage,
    VueQrcode
  },
  data() {
    return {
      TikTokID: "",
      money: "",
      number: 0,
      surplus: 0,
      drawer: false,
      pay_image: "",
      shareRatio: "",
      get_data: {},
      interval: "",
      kami: "",
      options: {
        scale: 6,
        width: 400,
        height: 256,
        color: {
          dark: '#000000ff',
          light: '#ffffffff'
        }
      }
    }
  },
  created() {

    let language = window.localStorage.getItem("language");
    if (language) {
      this.$i18n.locale = language;
    }

    const data = JSON.parse(window.localStorage.getItem("data"));
    if (!data) {
      this.$router.replace("/")
    } else {
      this.kami = data;
      this.surplus = data.data["balance"];
      this.shareRatio = parseInt(data.data["shareRatio"]) / 100;
      this.TikTokID = data.data["streamerId"]
    }

    // 从 URL 获取 secret 参数，如果没有则跳转到指定网址
    const urlParams = new URLSearchParams(window.location.search);
    let message = urlParams.get("message");
    if (message) {
      ElMessage.success(this.$t("pay.paySuccess"));
      // 清除 URL 中的 message 参数
      urlParams.delete("message");
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, "", newUrl);
      this.judge_kami(this.kami.data.cardId)
    }

    // console.log(data)
    // console.log(this.shareRatio)
  },
  methods: {
    paypal() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // let that = this;
      post("/paypal", {
        "kami": this.kami.data.cardId,
        "tiktok_id": this.TikTokID,
        "money": this.money,
        "game_id": this.kami.data.game_id
      }).then(res => {
        // console.log(res)
        if (res.code == 400) {
          loading.close()
          if (res.cnmsg == "tiktok账号不能为空") {
            ElMessage.error(this.$t("notify.tiktokidNULL"));
          } else if (res.cnmsg == "金额不能为空") {
            ElMessage.error(this.$t("notify.moneyNULL"));
          } else if (res.cnmsg == "卡密不能为空") {
            ElMessage.error(this.$t("notify.cardNULL"));
          }
        } else if (res.code == 200) {
          if (res.data.code === "success") {
            window.location.href = res.data.data.redirect_url
          }
          console.log(res)
        }

      })
    },
    go() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let that = this;
      post("/pay", {
        "kami": this.kami.data.cardId,
        "tiktok_id": this.TikTokID,
        "money": this.money,
        "game_id": this.kami.data.game_id
      }).then(res => {
        // console.log(res)
        loading.close()
        if (res.code == 400) {
          // ElMessage.error(res.cnmsg)
          if (res.cnmsg === "tiktok账号不能为空") {
            ElMessage.error(this.$t("notify.tiktokidNULL"));
          } else if (res.cnmsg === "金额不能为空") {
            ElMessage.error(this.$t("notify.moneyNULL"));
          } else if (res.cnmsg === "卡密不能为空") {
            ElMessage.error(this.$t("notify.cardNULL"));
          }
        } else if (res.code == 200 && res.data.code_url) {
          this.drawer = true
          this.pay_image = res.data.code_url

          this.interval = setInterval(function () {
            that.judge(res.data.order_id, this.interval)
          }, 1000)
          // console.log(this.pay_image)
        }
      })
    },
    num() {
      var other = this.shareRatio;
      if (!other) {
        other = 1
      }
      this.number = parseInt(this.money) / (7.2 * other) * 1000
    },
    closeDrawer() {
      // console.log(this.interval)
      clearInterval(this.interval)
    },
    judge(order_id, time) {
      let that = this;
      get("/judge_game_statue", {"order_id": order_id}).then(res => {
        // console.log(res)
        if (res.data.game_pay_statue == 1) {
          that.drawer = false;
          ElMessage.success(this.$t("pay.paySuccess"));
          clearInterval(time)
          that.judge_kami(res.data.card_id)
        }
      })
    },
    logout() {
      window.localStorage.removeItem("data");
      this.$router.push("/")
    },
    judge_kami(kami) {
      post("/judge_Kami", {
        "kami": kami,
        "game_id": this.kami.data.game_id
      }).then(res => {
        if (res.data["errorCode"] == 106) {
          // console.log(res.data)
          ElMessage.error(this.$t("pay.errorSuccess"))
        } else if (res.data["errorCode"] == 0) {
          // console.log(res.data)
          this.surplus = res.data.data["balance"];
          window.localStorage.setItem("data", JSON.stringify(res.data))
          this.$router.push("/pay")
        }
      }).catch(res => {
        console.log(res)
      })
    },
    gourl() {
      window.location.href = "https://www.xyhy.live"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 200px;
  height: auto;
  position: fixed;
  top: 20px;
  left: 20px;
}

.slgon {
  margin-top: 4%;
  font-size: 5rem;
  font-weight: bold;
  background: linear-gradient(to right, #2665EA 0%, #4ADC83 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 5px;
}

.text {
  font-size: 24px;
  color: #6B7280;
  margin-top: 100px;
}

.input {
  height: 40px;
  margin-top: 20px;
  border-radius: 5px;
}

.bottom {
  color: #6B7280;
  font-weight: bold;
  font-size: 15px;
  position: fixed;
  width: 100%;
  bottom: 20px;
}

.input_div {
  display: flex;
  align-content: center;
  justify-content: center;
}

.input_list {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-content: center;
  justify-content: center;
  font-weight: bold;
}

.button {
  margin-top: 30px;
  height: 40px;
}

.language {
  position: absolute;
  right: 20px;
  top: 40px;
}
</style>
