import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'

import router from './router';

// 引入 i18n
import {createI18n} from 'vue-i18n';

import zhCn from 'element-plus/dist/locale/zh-cn'; // 修改导入路径
import en from 'element-plus/dist/locale/en'; // 修改导入路径
import zhTw from "element-plus/dist/locale/zh-tw"
import ko from "element-plus/dist/locale/ko"
// 创建 i18n 实例
const i18n = createI18n({
    locale: 'zh-cn', // 设置语言环境
    messages: {
        'zh-cn': {
            "element": zhCn,
            "index": {
                "slogan": "星遇互游,让直播更简单",
                "customText": '每一个环节，都经过深思熟虑，只为了提供更好的直播服务。',
                "goButton": "点击确认",
                "placeholder": "请输入卡密",
                "cardKeyError": '卡密不存在或输入错误',
                "game_message": "请选择需要充值的游戏。"
            },
            "pay": {
                "gameName": "火星撞地球",
                "payText": "游戏充值",
                "payTotalTxt": "请仔细确认后，再点击充值",
                "CNY": "人民币",
                "CNYText": "请输入充值金额",
                "gameBalance": "游戏余额",
                "gameText": "游戏额度(输入金额自动计算)",
                "wechatPay": "点击充值(微信)",
                "paypalPay": "点击充值(PayPal)",
                "switchCard": "切换卡密",
                "wechatPayText": "请打开微信,扫描二维码，微信支付",
                "paySuccess": "充值成功,祝您开播愉快",
                "errorSuccess": "卡密不存在或输入错误，请核对后重试。"
            },
            "notify": {
                "tiktokidNULL": "tiktok账号不能为空",
                "moneyNULL": "金额不能为空",
                "cardNULL": "卡密不能为空"
            }
        },
        "zh-tw": {
            "element": zhTw,
            "index": {
                "slogan": "星遇互遊,讓直播更簡單",
                "customText": '每一個環節，都經過深思熟慮，只為了提供更好的直播服務。',
                "goButton": "點擊確認",
                "placeholder": "請輸入卡密",
                "cardKeyError": '卡密不存在或輸入錯誤，請核對後重試。',
                "game_message": "請選擇需要充值的遊戲。"
            },
            "pay": {
                "gameName": "火星撞地球",
                "payText": "遊戲充值",
                "payTotalTxt": "請仔細確認後，再點擊充值",
                "CNY": "CNY",
                "CNYText": "請輸入充值金額",
                "gameBalance": "遊戲余額",
                "gameText": "遊戲額度(輸入金額自動計算)",
                "wechatPay": "點擊充值(微信)",
                "paypalPay": "點擊充值(PayPal)",
                "switchCard": "切換卡密",
                "wechatPayText": "請打開微信,掃描二維碼，微信支付",
                "paySuccess": "充值成功,祝您開播愉快",
                "errorSuccess": "卡密不存在或輸入錯誤，請核對後重試。"
            },
            "notify": {
                "tiktokidNULL": "tiktok賬號不能為空",
                "moneyNULL": "金額不能為空",
                "cardNULL": "卡密不能為空"
            }
        },
        'en': {
            "element": en,
            "index": {
                "slogan": "Star Encounter,Make live streaming easier",
                "customText": 'Every aspect has been carefully considered to provide better live streaming services.',
                "goButton": "Go",
                "placeholder": "Please enter the card number",
                "cardKeyError": 'The card key does not exist or the input is incorrect, please try again after checking.',
                "game_message": "Please select the game you need to recharge."
            },
            "pay": {
                "gameName": "Mars vs Earth",
                "payText": "Game recharge",
                "payTotalTxt": "Please confirm it carefully, and then click recharge.",
                "CNY": "CNY",
                "CNYText": "Please enter the recharge amount.",
                "gameBalance": "Game balance",
                "gameText": "Game quota",
                "wechatPay": "Click to recharge (WeChat)",
                "paypalPay": "Click to recharge (PayPal)",
                "switchCard": "Switching card number",
                "wechatPayText": "Please open wechat, scan the QR code, wechat Pay",
                "paySuccess": "Top up successfully. Have a nice start",
                "errorSuccess": "The password does not exist or the input is incorrect. Please check and try again"
            },
            "notify": {
                "tiktokidNULL": "TikTok account cannot be empty",
                "moneyNULL": "The amount cannot be empty",
                "cardNULL": "The password cannot be empty"
            }
        },
        'ko': {
            "element": ko,
            "index": {
                "slogan": "Star Encounter,생방송을 더욱 쉽게 할 수 있도록 하다.",
                "customText": '매 환절마다 심사숙고를 거쳐 오직 더욱 좋은 생방송서비스를 제공하기 위해서이다.',
                "goButton": "확인 클릭",
                "placeholder": "암호를 입력하십시오.",
                "cardKeyError": '비밀 카드가 존재하지 않거나 입력 오류가 있습니다. 대조 후 다시 시도하십시오.',
                "game_message": "충전이 필요한 게임을 선택해 주세요."
            },
            "pay": {
                "gameName": "Mars vs Earth",
                "payText": "게임 충전",
                "payTotalTxt": "자세한 확인 후 충전 클릭",
                "CNY": "CNY",
                "CNYText": "충전 금액을 입력하세요",
                "gameBalance": "게임 잔액",
                "gameText": "게임 한도 (입력 금액 자동 계산)",
                "wechatPay": "클릭하여 충전(wechat)",
                "paypalPay": "클릭하여 충전(PayPal)",
                "switchCard": "전환",
                "wechatPayText": "Wechat을 열고 QR코드, Wechat Pay를 스캔하십시오.",
                "paySuccess": "충전 성공, 즐거운 방송 되세요",
                "errorSuccess": "암호가 없거나 입력 오류가 있습니다. 확인 후 다시 시도하십시오."
            },
            "notify": {
                "tiktokidNULL": "틱톡 계정은 비워둘 수 없습니다.",
                "moneyNULL": "금액은 비워둘 수 없습니다.",
                "cardNULL": "커미는 비워둘 수 없습니다"
            }
        }
    }
});


const app = createApp(App)

app.use(ElementPlus)

app.use(i18n);

app.use(router)
app.mount('#app')
