<template>
  <div class="language">
    <swithLanguage></swithLanguage>
  </div>
  <div class="hello">
    <img src="../assets/logo.png" class="logo" alt="" @click="gourl">
    <div class="slgon">{{ $t('index.slogan') }}</div>
    <div class="text"> {{ $t('index.customText') }}</div>
    <div>
      <input v-model="password" class="input" :placeholder="$t('index.placeholder')">
      <el-select v-model="value" placeholder="请选择" class="select" size="large">
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.game_title"
            :value="item.id">
        </el-option>
      </el-select>
      <el-button @click="go" type="primary" size="large" color="#636ff1" style="margin-left: 20px;height: 40px;">
        {{ $t('index.goButton') }}
      </el-button>
    </div>
    <div class="bottom">
      星遇互游 © 2024 All Rights by 湖北千纳文化发展有限公司.<br>
      鄂ICP备2021020027号-1
    </div>
  </div>
</template>

<script>
import {ElLoading, ElMessage} from 'element-plus'
import {get, post} from "@/api";
import swithLanguage from "@/components/swithLanguage.vue";

export default {
  name: 'HelloWorld',
  components: {swithLanguage},
  data() {
    return {
      password: "",
      options: [],
      value: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      get("/get_game_list").then(res => {
        console.log(res)
        this.options = res.data
      }).catch(res => {
        console.log(res)
      })
    },
    go() {
      if (!this.value) {
        return ElMessage.error(this.$t('index.game_message'))
      }

      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      //get请求
      post("/judge_Kami", {
        "kami": this.password,
        "game_id": this.value
      }).then(res => {
        loading.close()
        if (res.data["errorCode"] == 106 || res.code == 400 || res.data["errorCode"] == 1) {
          console.log(res.data)
          // ElMessage.error(res.cnmsg)
          if (res.cnmsg == "卡密不能为空") {
            ElMessage.error(this.$t('notify.cardNULL'))
          } else {
            ElMessage.error(this.$t('index.cardKeyError'))
          }
        } else if (res.data["errorCode"] == 0) {
          console.log(res.data)
          window.localStorage.setItem("data", JSON.stringify(res.data))
          this.$router.push("/pay")
        }
      }).catch(res => {
        console.log(res)
      })
    },
    gourl() {
      window.location.href = "https://www.xyhy.live"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 200px;
  height: auto;
  position: fixed;
  top: 20px;
  left: 20px;
}

.slgon {
  margin-top: 10%;
  font-size: 5rem;
  font-weight: bold;
  background: linear-gradient(to right, #2665EA 0%, #4ADC83 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  padding: 5px;
}

.text {
  font-size: 24px;
  color: #6B7280;
  margin-top: 100px;
  font-weight: bold;
}

.input {
  width: 30%;
  height: 40px;
  margin-top: 100px;
  border-radius: 5px;
}

.select {
  width: 7%;
  min-width: 100px;
}

.bottom {
  color: #6B7280;
  font-weight: bold;
  font-size: 15px;
  position: fixed;
  width: 100%;
  bottom: 20px;
}

.language {
  position: absolute;
  right: 20px;
  top: 40px;
}
</style>
